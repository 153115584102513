import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { Dropdown } from '../elements'
import { getRequest } from '../../../services/api'
import { getHelpArticles } from '../../../services/auth'
import { helpArticlePath } from '../../../utils/paths'
import { isHeaderlessLayout } from '../../../utils/helpers'

export const headerHeight = 60

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight}px;
  padding: 0 24px;
  color: black;
  background: var(--header-background);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  z-index: 999;

  .logo {
    svg {
      height: 36px;
      margin-left: 8px;
    }
  }

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .control-menu {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 34px;
      padding: 5px 10px;
      margin-left: 8px;
      margin-right: 8px;
      border: 1px solid var(--header-background-hover);
      border-radius: 8px;
      color: black;
      outline: none;
      transition: border-color 0.3s ease-in-out;

      &:hover,
      &.open {
        border-color: var(--header-background-darken);
      }

      &.open {
        svg.arrow {
          transform: rotate(180deg);
        }
      }

      span {
        margin-left: 8px;
        margin-right: 8px;
        font-family: var(--head-font);
        font-weight: normal;
        font-size: 13px;
      }
    }

    .control-menu-btn {
      padding: 8px 34px;
      border: none;
      border-radius: 17px;
      background: var(--btn-background);
      color: var(--btn-color);
      transition: background 0.3s;

      span {
        margin: 0;
        font-weight: normal;
        font-size: 14px;
      }

      &:hover {
        background: var(--btn-background-hover);
      }
    }

    li {
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 500;
    }

    .control-menu-link {
      display: block;
      padding: 6px 15px;
      border-radius: 4px;
      color: var(--text-primary);
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      svg {
        margin-top: -2px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .connection-ring {
      position: relative;
      padding: 6px 15px;
      padding-left: 38px;
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 500;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 7px;
        left: 14px;
        width: 14px;
        height: 14px;
        border: 3px solid #d3d5e3;
        border-radius: 7px;
      }

      &.active {
        &:before {
          border-color: #50c1a8;
          box-shadow: 0 2px 4px 0 rgba(40, 157, 60, 0.3);
        }
      }

      &.inactive {
        &:before {
          border-color: #f57d4f;
          box-shadow: 0 2px 4px 0 rgba(157, 60, 40, 0.3);
        }
      }
    }
  }
`

export const Header = (props) => {
  const [isHeaderless, setHeaderless] = useState(null)

  useEffect(() => {
    setHeaderless(isHeaderlessLayout())
  }, [])

  if (isHeaderless) {
    return null
  }

  return (
    <header>
      <HeaderContainer>
        <div className="logo">
          <SVG src="/images/logo-dark.svg" width={180} />
        </div>
        <div className="control">
          <HeaderHelp />
        </div>
      </HeaderContainer>
    </header>
  )
}

export const HeaderHelp = () => {
  const [articles, setArticles] = useState(null)

  useEffect(() => {
    if (articles === null) {
      const newArticles = getHelpArticles()

      if (newArticles) {
        setArticles(newArticles)
      } else {
        getRequest('/signup/articles/help').then((response) => {
          setArticles(response?.data?.articles)
          // setHelpArticles(response?.data?.articles)
        })
      }
    }
  }, [articles])

  return (
    <Dropdown
      buttonClassName="control-menu"
      button={
        <>
          <SVG src="/images/icons/header-question.svg" width={22} />
          <span>Help</span>
          <SVG
            src="/images/icons/arrow-down.svg"
            className="arrow"
            width={14}
          />
        </>
      }
    >
      {articles ? (
        <>
          {articles.map((article) => (
            <li key={article.uuid}>
              <a
                href={helpArticlePath(article.slug)}
                className="control-menu-link"
              >
                {article.title}
              </a>
            </li>
          ))}
        </>
      ) : null}
    </Dropdown>
  )
}
